import React, { Component } from "react";
import { withRouter } from "react-router";

import swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import moment from "moment";

import debounce from "lodash.debounce";

import axios from "../../axios-instance.js";

import Container from "../../components/UI/Container/Container.js";
import Icon from "../../components/UI/Icon/Icon.js";
import { Stepper } from "../../components/Stepper/Stepper.js";
import NewLoader from "../../components/NewLoader/NewLoader.js";

import { FillFields, ChoosePaymentMethod, SelectTickets } from "./Steps/index.js";
import TermsAndConditionsModal from "../SeasonPass/TermsAndConditionsModal/TermsAndConditionsModal.js";
import { bankLogos, fee, paymentMethodOptions } from "../SeasonPass/constants.js";
import { getCurrencieLabel } from "../../constants/currencies.js";
import { getTotal } from "../SeasonPass/helpers/index.js";
import { filterUnavailableTicketTemplates } from "../../helpers/filterUnavailableTicketTemplates.js";
import { showInfoModal, showErrorModal } from "../../modals/index.js";

import PleaseWait from "../../images/please-wait-season-pass-modal.svg";
import ThanksForYourPurchase from "../../images/thanks-for-your-purchase.svg";

import "../SeasonPass/SeasonPass.sass";
import "./OpenSell.sass";
import { isValidEmail } from "../../helpers/validators.js";
import { getRegion } from "../../helpers/getRegion";
import { isNoSeatTicket, isSeatsIoTicket } from "../../helpers/ticketHelper.ts";
import { getPrice, getTotalFee, recalculatePrice } from "../../helpers/ticketPriceHelper.ts";
import { executePayment } from "../../helpers/paymentHelper/paymentHelper.ts";
import { paymentTypes } from "../../constants/paymentType.ts";
import { SeasonPassFieldLabel } from "../SeasonPass/SeasonPassFieldLabel.js";
import Select from "react-select";

const MySwal = withReactContent(swal);

export const mapSeatIo = ({
  category: { label, key },
  labels: { section, parent, own },
  pricing: { price },
  originalPrice,
  eventData,
}) => {
  const [area, side] = section.split("-");
  const ticketTemplate = eventData.ticketTemplates.find(
    ({ categoryKey }) => +categoryKey === +key
  );
  const ticketTemplateId = ticketTemplate && ticketTemplate.ticketTemplateId;

  if (!originalPrice) {
    originalPrice = price;
  }

  return {
    ticketTemplateId,
    ticketName: label,
    area: area,
    row: parent,
    seat: own,
    side: side,
    amount: price,
    fixedDiscount: +(+originalPrice - +price).toFixed(2),
  };
};

export const mapSeat = (seats, ticket, i, coupon) => {
  const { ticketTemplateId, count } = ticket;

  for (let j = 0; j < count; j++) {
    let fixedDiscount = 0;
    if (coupon && (!coupon.ticketTypeIds || coupon.ticketTypeIds.length === 0)) {
      fixedDiscount = coupon.fixedDiscount;
    }
    else if (coupon && coupon.ticketTypeIds && coupon.ticketTypeIds.filter((v) => (v === ticketTemplateId)).length > 0 && coupon.ticketTypeIds.filter((v) => (v === ticketTemplateId)).length <= count && j < coupon.ticketTypeIds.filter((v) => (v === ticketTemplateId)).length) {
      fixedDiscount = coupon.fixedDiscount;
    }

    if (!fixedDiscount) {
      fixedDiscount = 0;
    }

    if (!ticket.price) {
      ticket.price = 0;
    }

    if (!ticket.originalPrice) {
      ticket.originalPrice = ticket.price;
    }

    const seat = {
      ticketTemplateId: ticketTemplateId,
      discount: (i == 0 && j < 2 && coupon) ? coupon.discount : 0,
      fixedDiscount: +(fixedDiscount + +ticket.originalPrice - +ticket.price).toFixed(2),
    };

    seats.push(seat);
  }

  return seats;
};

class OpenSell extends Component {
  state = {
    fields: {
      fullName: "",
      physicalAddress: "",
      city: "",
      postalCode: "",
      phoneNumber: "",
      buyerEmail: "",
      birthday: null,
      gender: "",
      paymentMethod: null,
      companyName: "",
      companyVatNumber: "",
    },
    checkboxeFields: {
      termsAndConditionsChecked: false,
    },
    tickets: [],
    transactionData: {
      orderId: "",
      paymentAmount: "",
      currencyCode: "RSD",
    },
    eventData: null,
    isOffers: false,
    step: 1,
    themeColor: "#F95700",
    hasSeats: undefined,
    feeRate: fee,
    fixedFeeRate: 0,
    loading: true,
    submittBlocked: false,
    reapetedBuyerEmail: "",
    coupon: null,
  };

  handleChangeStep = (nextStep) => {
    this.setState({ step: nextStep });
  };

  setOrderDataToState = (data) => {
    const fields = {
      ...this.state.fields,
      fullName: data.fullName,
      physicalAddress: data.physicalAddress,
      city: data.city,
      postalCode: data.postalCode,
      phoneNumber: data.phoneNumber,
      buyerEmail: data.buyerEmail,
      birthday: data.birthday ? data.birthday : null,
      gender: data.gender,
      companyName: data.companyName,
      companyVatNumber: data.companyVatNumber,
    };

    this.setState({
      fields,
    });
  };

  showSalesUnavailableModal = () => {
    MySwal.fire({
      title: "Sales not available",
      allowEscapeKey: false,
      customClass: "seasonpass__modal partizan-info-modal",
      allowOutsideClick: false,
      allowEnterKey: false,
      showConfirmButton: false,
    });
  };

  showPleaseWaitModal = () => {
    MySwal.fire({
      imageUrl: PleaseWait,
      title: "Please wait",
      text: "You will be redirected to the payment page",
      allowEscapeKey: false,
      customClass: "seasonpass__modal partizan-info-modal",
      allowOutsideClick: false,
      allowEnterKey: false,
      showConfirmButton: false,
    });
  };

  getTransactionData = async () => {
    try {
      const { data } = await axios.get(
        `Transaction/${this.props.match.params.id}`
      );
      if (data) {
        this.handleOrderStatuses(data);
        this.setOrderDataToState(data);
      }
    } catch (e) {
      showErrorModal({
        text: `It seems to have failed to complete the payment, please try again`,
        customClass:
          "seasonpass__modal_info seasonpass__modal partizan-info-modal",
      });
      console.log(e);
    }
  };

  setPrice = () => {
    const { tickets, coupon } = this.state;

    recalculatePrice(tickets);

    this.setTransactionData({
      paymentAmount: getPrice(tickets, coupon),
    });
  };

  handleOrderStatuses = (data) => {
    switch (data.paymentStatus) {
      //New
      case 0:
        break;
      //Success
      case 1:
        showInfoModal({
          image: ThanksForYourPurchase,
          title: "Thanks for Your Purchase!",
          text: `We will send all the details to your inbox (${data.buyerEmail}) and look forward to meeting you`,
          additionalText: `(Payment Status: ${data.additionalPaymentStatus
            }, Payment Date: ${moment(data.paymentDate).format(
              "DD MMMM YYYY HH:mm"
            )}, Transaction Id: ${data.transactionId}, Auth Code: ${data.authCode
            })`,
          customClass:
            "seasonpass__modal_info seasonpass__modal partizan-info-modal",
        });
        this.setState({ submittBlocked: true });
        break;
      //Failed
      case 2:
        showErrorModal({
          text: `It seems to have failed to complete the payment, please try again`,
          customClass:
            "seasonpass__modal_info seasonpass__modal partizan-info-modal",
          additionalText: `(Payment Status: ${data.additionalPaymentStatus
            }, Payment Date: ${moment(data.paymentDate).format(
              "DD MMMM YYYY HH:mm"
            )}, Transaction Id: ${data.transactionId}, Auth Code: ${data.authCode
            })`,
        });
        break;
      //Cancelled
      case 3:
        break;
      default:
        break;
    }
  };

  createPathnameWithOrderId = (id) => {
    const pathname = String(this.props.location.pathname);
    const splitedPathname = pathname.split("/");
    const isIncludeId = splitedPathname.length >= 4;
    if (isIncludeId) {
      return `/${splitedPathname[1]}/${splitedPathname[2]}/${id}`;
    }
    return pathname + "/" + id;
  };

  createRedirectURL = (id) => {
    const { isOffers } = this.state;

    if (isOffers) {
      return window.location.href + "?";
    }

    const origin = window.location.origin;
    const pathname = this.createPathnameWithOrderId(id);

    return `${origin}${pathname}?`;
  };

  setTransactionData = (data) => {
    const paymentAmount = data.paymentAmount;

    const orderId = data.orderId || this.state.transactionData.orderId || "";

    const transactionData = {
      ...this.state.transactionData,
      ...data,
      orderId,
      redirectURL: this.createRedirectURL(orderId),
      resURL: `${process.env.API_URL}/Payment/Webhook/Kopa`,
    };

    if (paymentAmount) {
      transactionData.paymentAmount = getTotal(
        paymentAmount,
        this.state.feeRate,
        this.state.fixedFeeRate * this.state.tickets.length,
      );
    }

    this.setState({
      transactionData,
    });
  };

  handleSubmit = async () => {
    try {
      this.showPleaseWaitModal();

      const {
        fields: { paymentMethod, ...otherFields },
        eventData,
        tickets,
      } = this.state;

      await executePayment({
        eventData: eventData,
        paymentMethod: paymentMethod.id,
        paymentType: paymentTypes.online,
        couponCode: '',
        redirectUrl: this.createRedirectURL('{Id}'),
        tickets: tickets,
        transaction: {
          ...otherFields,
          id: this.props.match.params.transactionId || '',
        }
      });

    } catch (e) {
      console.log(e);
      showErrorModal({
        text: `It seems to have failed to complete the payment, please try again`,
        customClass:
          "seasonpass__modal_info seasonpass__modal partizan-info-modal",
      });
    }
  };

  handleChange = (name, value) => {
    if (name) {
      this.setState({ fields: { ...this.state.fields, [name]: value } });
    }
  };

  handleRepaetEmailChange = debounce((value) => {
    this.setState({ reapetedBuyerEmail: value });
  }, 700);

  showTermsAndConditionsModal = (ev) => {
    ev.preventDefault();
    MySwal.fire({
      html: <TermsAndConditionsModal />,
      width: "64em",
      customClass: "seasonpass__modal partizan-info-modal",
    });
  };

  handleChangeCheckbox = (name) => {
    if (name) {
      this.setState({
        checkboxeFields: {
          ...this.state.checkboxeFields,
          [name]: !this.state.checkboxeFields[name],
        },
      });
    }
  };

  checkActionButtonDisabled = () => {
    const {
      step,
      fields: {
        companyName,
        companyVatNumber,
        paymentMethod,
        birthday,
        ...otherFields
      },
      checkboxeFields,
      submittBlocked,
      tickets,
      loading,
      isOffers,
      reapetedBuyerEmail,
    } = this.state;

    if (otherFields.buyerEmail && !isValidEmail(otherFields.buyerEmail))
      return true;

    if (submittBlocked || loading) return true;

    if (isOffers) return false;

    switch (step) {
      case 1:
        return !tickets.length;
      case 3:
        return !paymentMethod || reapetedBuyerEmail !== otherFields.buyerEmail;
      case 2:
        return (
          !checkboxeFields.termsAndConditionsChecked ||
          !Object.keys(otherFields).every((key) => !!otherFields[key]) ||
          !moment(birthday).isValid()
        );
      default:
        return false;
    }
  };

  onTicketAdded = (position) => {
    if (isNoSeatTicket(position)) {
      let findedTicket = this.state.tickets.find(
        ({ ticketTemplateId }) => ticketTemplateId === position.ticketTemplateId
      );

      if (findedTicket) {
        this.setState(
          {
            tickets: this.state.tickets.map((ticket) =>
              ticket.ticketTemplateId === position.ticketTemplateId ? position : ticket
            ),
          },
          () => this.setPrice()
        );
        return;
      }
    }
    if (isSeatsIoTicket(position) && !position.pricing) {
      return;
    }
    const tickets = [...this.state.tickets, position];

    this.setState({ tickets }, () => this.setPrice());
  };

  onObjectDeselected = (position) => {
    if (isNoSeatTicket(position)) {
      this.setState(
        {
          tickets: this.state.tickets.filter(
            (item) => item.ticketTemplateId !== position.ticketTemplateId
          ),
        },
        () => this.setPrice()
      );
    }
    else {
      this.setState(
        {
          tickets: this.state.tickets.filter((item) => item.id !== position.id),
        },
        () => this.setPrice()
      );
    }
  };

  setEventData = (data = {}, shouldfilterUnavailable = true) => {
    document.documentElement.style.setProperty(
      "--partizan-theme",
      this.state.themeColor
    );

    if (!data.isSalesAvailable) {
      this.setState({ submittBlocked: true });
      this.showSalesUnavailableModal();
    }

    if (shouldfilterUnavailable) {
      data.ticketTemplates = filterUnavailableTicketTemplates(
        data.ticketTemplates
      );
    }

    this.setState({
      transactionData: {
        ...this.state.transactionData,
        accessToken: data.paymentToken,
        currencyCode: getCurrencieLabel(data.currencyId),
      },
      hasSeats: data.ticketTemplates.length
        ? data.ticketTemplates.filter(t => t.hasSeats) >= data.ticketTemplates.filter(t => !t.hasSeats)
        : undefined,
      feeRate: data.feeRate || fee,
      fixedFeeRate: data.fixedFeeRate || 0,
      eventData: data,
      fields: {
        ...this.state.fields,
        paymentMethod: {
          id: data.allowedPaymentProviders[0].providerId,
          value: paymentMethodOptions[data.allowedPaymentProviders[0].providerId],
          label: data.allowedPaymentProviders[0].methods[0].name,
        }
      }
    });
  };

  getEventData = async (transactionId) => {
    if (transactionId) {
      const { data } = await axios.get(
        `/EventPublicInfo/ByTransactionId/${transactionId}`
      );

      this.setEventData({ ...data }, false);
      return;
    }

    const eventSlug = this.props.match.params.slug;
    const { data } = await axios.get(`/EventPublicInfo/BySlug/${eventSlug}`);

    this.setEventData({
      ...data,
    });
  };

  getOfferTransactionData = async () => {
    if (!this.props.match.params.transactionId) {
      return;
    }

    const transactionId = this.props.match.params.transactionId;

    let tickets = [];

    await this.getEventData(transactionId);

    const { data } = await axios.get(
      `Transaction/TransactionCreationModel/${transactionId}`
    );

    const serializedTransactionModel = JSON.parse(
      data.serializedTransactionCreationModel
    );

    const transactionModel = {};

    for (const key in serializedTransactionModel) {
      if (serializedTransactionModel.hasOwnProperty(key)) {
        transactionModel[key] = serializedTransactionModel[key];
      }
    }

    this.handleOrderStatuses(transactionModel);
    this.setOrderDataToState(transactionModel);

    tickets = serializedTransactionModel.seats.map((seat) => ({
      ticketTemplateId: seat.ticketTemplateId,
      count: 1,
      // name: getSeatTextDescription(seat), ??? why
      name: seat.ticketName,
      ticketName: seat.ticketName,
      amount: seat.amount,
      price: +seat.amount,
      area: seat.area,
      row: seat.row,
      seat: seat.seat,
      side: seat.side,
      fixedDiscount: seat.fixedDiscount,
    }));

    this.setState(
      {
        tickets,
        hasSeats: false,
      },
      () => {
        this.setPrice();
      }
    );
  };

  callAPIs = async () => {
    try {
      this.setState({ loading: true });
      const { id, slug, transactionId } = this.props.match.params;

      switch (true) {
        case !!slug:
          await this.getEventData();
          break;
        case !!transactionId:
          this.setState({ isOffers: true });
          await this.getOfferTransactionData();
          break;
        default:
          throw Error();
      }

      if (id) {
        await this.getTransactionData();
      }

      this.setState({ loading: false });
    } catch (e) {
      this.setState({ loading: false });
      showErrorModal({
        text: `It seems to have failed to complete the payment, please try again`,
        customClass:
          "seasonpass__modal_info seasonpass__modal partizan-info-modal",
      });
      console.log(e);
    }
  };

  componentDidMount() {
    this.callAPIs();
  }

  render() {
    const {
      step,
      fields,
      coupon,
      checkboxeFields,
      tickets,
      transactionData,
      eventData,
      loading,
      hasSeats,
      feeRate,
      fixedFeeRate,
      isOffers,
      reapetedBuyerEmail,
    } = this.state;

    this.setCouponHandler = (c) => {
      console.log(c);
      this.setState({ coupon: c });
      this.setPrice();
      this.render();
    };

    this.setCouponHandler = this.setCouponHandler.bind(this);

    if (loading && !isOffers) {
      return <NewLoader isFullScreen />;
    }

    if (!eventData) {
      return null;
    }

    const time = moment(eventData.startDate).format("DD.MM.YYYY HH:mm");

    const pricing = eventData.ticketTemplates.map(({ categoryKey, price }) => ({
      category: categoryKey,
      price,
    }));

    const prefixLabel = eventData.prefixLabel || "";
    const region = getRegion();

    const location = eventData.place || "";

    const { companyVatNumber, companyName, ...otherFields } = fields;
    const requiredFields = Object.keys(otherFields);
    const totalFee = getTotalFee(tickets, fixedFeeRate, feeRate);

    const offerSteps = [
      {
        title: "Buy tickets",
        component: (
          <div className="partizan-open-selling__offerWrapper">
            <SelectTickets
              totalFee={totalFee}
              isOffers
              tickets={tickets}
              ticketTemplates={eventData.ticketTemplates}
              pricing={pricing}
              hasSeats={hasSeats}
              workspaceKey={eventData.seatsIoWorkspaceId}
              eventSeatsId={eventData.seatsIoEventId}
              event={eventData}
              loading={loading}
              currency={transactionData.currencyCode}
              onObjectSelected={this.onTicketAdded}
              onObjectDeselected={this.onObjectDeselected}
              total={transactionData.paymentAmount}
              setCouponHandler={this.setCouponHandler}
              coupon={coupon}
            />

            <SeasonPassFieldLabel
              label="Payment method"
              isRequired={true}
              className="partizan-open-selling__payment-method"
            >
              <Select
                options={eventData.allowedPaymentProviders.flatMap(p => {
                  return p.methods.map(m => {
                    return {
                      id: p.providerId,
                      value: paymentMethodOptions[p.providerId],
                      label: m.name,
                    }
                  })
                })}
                name="paymentMethod"
                value={eventData.allowedPaymentProviders.flatMap(p => {
                  return p.methods.map(m => {
                    return {
                      id: p.providerId,
                      value: paymentMethodOptions[p.providerId],
                      label: m.name,
                    }
                  })
                }).find(
                  ({ value }) => fields.paymentMethod.id === value.id
                )}
                onChange={(value) => this.handleChange("paymentMethod", value)}
                classNamePrefix="custom-select"
                className="partizan-open-selling__select"
              />
            </SeasonPassFieldLabel>

          </div>
        ),
      },
    ];

    const steps = [
      {
        title: "Select Tickets",
        component: (
          <SelectTickets
            totalFee={totalFee}
            tickets={tickets}
            ticketTemplates={eventData.ticketTemplates}
            maxSelectedObjects={eventData.maxSeatsToSelectPerTransaction}
            pricing={pricing}
            hasSeats={hasSeats}
            workspaceKey={eventData.seatsIoWorkspaceId}
            eventSeatsId={eventData.seatsIoEventId}
            event={eventData}
            currency={transactionData.currencyCode}
            onObjectSelected={this.onTicketAdded}
            onObjectDeselected={this.onObjectDeselected}
            total={transactionData.paymentAmount}
            setCouponHandler={this.setCouponHandler}
            coupon={coupon}
          />
        ),
      },
      {
        title: "Fill in the info",
        component: (
          <FillFields
            fields={fields}
            requiredFields={requiredFields}
            handleChange={this.handleChange}
            checkboxeFields={checkboxeFields}
            showTermsAndConditionsModal={this.showTermsAndConditionsModal}
            handleChangeCheckbox={this.handleChangeCheckbox}
          />
        ),
      },
      {
        title: "Buy tickets",
        component: (
          <ChoosePaymentMethod
            buyerEmail={fields.buyerEmail}
            reapetedBuyerEmail={reapetedBuyerEmail}
            handleRepaetEmailChange={this.handleRepaetEmailChange}
            totalFee={totalFee}
            hasSeats={hasSeats}
            currency={transactionData.currencyCode}
            email={fields.buyerEmail}
            tickets={tickets}
            total={transactionData.paymentAmount}
            paymentMethod={fields.paymentMethod}
            handleChange={this.handleChange}
            setCouponHandler={this.setCouponHandler}
            coupon={coupon}
            allowEnterCouponCode={false}
            paymentMethodOptions={eventData.allowedPaymentProviders.flatMap(p => {
              return p.methods.map(m => {
                return {
                  id: p.providerId,
                  value: paymentMethodOptions[p.providerId],
                  label: m.name,
                }
              })
            })}
          />
        ),
      },
    ];

    const headerComponent = (
      <div>
        <h2 className="partizan-open-selling__stepper-header-event-name">
          {prefixLabel}
        </h2>
        <h2 className="partizan-open-selling__stepper-header-title">
          {eventData.eventName}
        </h2>
        <h5 className="partizan-open-selling__stepper-header-subtitle">
          Supported by {region.title}
        </h5>
        <div className="partizan-open-selling__stepper-header-info">
          <div className="partizan-open-selling__stepper-header-info-location">
            <Icon name={"map-pin-solid"} />
            <p>{location}</p>
          </div>
          <p className="partizan-open-selling__stepper-header-info-time">
            {time}
          </p>
        </div>
      </div>
    );

    const offerActionComponent = (
      <div className="partizan-open-selling__stepper-action">
        <div
          style={{ justifyContent: "flex-end" }}
          className="partizan-open-selling__stepper-action-btns"
        >
          <button
            className="btn-primary"
            type="button"
            disabled={this.checkActionButtonDisabled()}
            onClick={this.handleSubmit}
          >
            Pay
          </button>
        </div>
        <div className="seasonpass__bank-logos">
          {bankLogos.map(({ alt, src, link }) => (
            <div key={alt}>
              <a target="_blank" rel="noopener noreferrer" href={link}>
                <img style={{ height: 30 }} src={src} alt={link} />
              </a>
            </div>
          ))}
        </div>
      </div>
    );

    const actionComponent = (
      <div className="partizan-open-selling__stepper-action">
        <div className="partizan-open-selling__stepper-action-btns">
          <button
            className="btn-secondary"
            type="button"
            disabled={step === 1}
            onClick={() => this.handleChangeStep(step - 1)}
          >
            Previous
          </button>
          {steps.length === step && (
            <button
              className="btn-primary"
              type="button"
              disabled={this.checkActionButtonDisabled()}
              onClick={this.handleSubmit}
            >
              Pay
            </button>
          )}
          {steps.length !== step && (
            <button
              className="btn-primary"
              type="button"
              disabled={this.checkActionButtonDisabled()}
              onClick={() => this.handleChangeStep(step + 1)}
            >
              Next
            </button>
          )}
        </div>
        <div className="seasonpass__bank-logos">
          {bankLogos.map(({ alt, src, link }) => (
            <div key={alt}>
              <a target="_blank" rel="noopener noreferrer" href={link}>
                <img style={{ height: 30 }} src={src} alt={link} />
              </a>
            </div>
          ))}
        </div>
      </div>
    );

    return (
      <Container className="seasonpass partizan-open-selling">

        <form className="partizan-open-selling__wrapper">
          <div className="partizan-open-selling__logo seasonpass__header-logo-wrapper">
            <img src={`/${region.theme}/logo.png`} alt="Logo" />
            <h2>{region.title}</h2>
          </div>
          <Stepper
            headerBgStyle={{
              backgroundImage: `url(${eventData.imageUrl})`,
            }}
            headerWrapperClass="partizan-open-selling__stepper-header"
            headerComponent={headerComponent}
            actionComponent={isOffers ? offerActionComponent : actionComponent}
            currentStep={step}
            steps={isOffers ? offerSteps : steps}
          />
        </form>

      </Container>
    );
  }
}

export default withRouter(OpenSell);
