export const getRegion = () => {
  const region = localStorage.getItem("region"); // dev method
  
  const host = window.location.host;

  const url = window.location.origin;

  switch (region) { // host for prod
    case "fanceetickets":
      return {
        url,
        theme: "fanceetickets",
        title: "FanceeTickets",
      };

    case "sixtix":
      return {
        url,
        theme: "sixtix",
        title: "SixTix",
      };

    case "france":
      return {
        url,
        theme: "france",
        title: "France",
      }

    default:
      localStorage.setItem("region", "sixtix"); // dev feature

      return {
        url,
        theme: "sixtix",
        title: "SixTix",
      }
  }
}